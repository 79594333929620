<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Administradora *" />
      <BorderSelect
        v-model="building.data.companyId"
        label="Administradora *"
        :options="store.state.general.companies"
      />
      <FormError
        :show="building.rules.companyId"
        message="Seleccione una administradora"
      />
      <BasicLabel label="Nombre *" />
      <BorderInput
        v-model="building.data.name"
        label="Nombre *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="building.rules.name"
        message="Ingrese un nombre sin caracteres raros"
      />
      <BasicLabel v-if="false" label="Tipo de Documento *" />
      <BorderSelect
        v-if="false"
        v-model="building.data.identityTypeId"
        label="Tipo de Documento *"
        :options="store.state.general.identityType"
      />
      <FormError
        :show="building.rules.identityTypeId"
        message="Seleccione el tipo de documento"
      />
      <BasicLabel v-if="false" label="Rut / CI *" />
      <BorderInput
        v-if="false"
        v-model="building.data.identity"
        label="Rut / CI *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="building.rules.identity"
        message="Ingrese un número de documento válido sin espacios, puntos y guiones"
      />
      <BasicLabel v-if="false" label="País *" />
      <BorderSelect
        v-if="false"
        v-model="building.data.countryId"
        label="País *"
        :options="store.state.general.countries"
      />
      <FormError
        :show="building.rules.countryId"
        message="Seleccione un país"
      />
      <BasicLabel label="Departamento *" />
      <BorderSelect
        v-model="building.data.stateId"
        :disabled="!building.data.countryId"
        label="Departamento *"
        :options="
          store.state.general.states.filter(
            (item) => item.countryId === building.data.countryId
          )
        "
      />
      <FormError
        :show="building.rules.stateId"
        message="Seleccione un departamento"
      />
      <BasicLabel label="Ciudad *" />
      <BorderSelect
        v-model="building.data.cityId"
        :disabled="!building.data.stateId"
        label="Ciudad *"
        :options="
          store.state.general.cities.filter(
            (item) => item.stateId === building.data.stateId
          )
        "
      />
      <FormError
        :show="building.rules.cityId"
        message="Seleccione una ciudad"
      />
      <BasicLabel label="Dirección *" />
      <BorderInput
        v-model="building.data.address"
        label="Dirección *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="building.rules.address"
        message="Ingrese una dirección"
      />
      <BasicLabel v-if="false" label="Email *" />
      <BorderInput
        v-if="false"
        v-model="building.data.email"
        label="Email *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="building.rules.email"
        message="Ingrese un email válido"
      />
      <BasicLabel label="Teléfono" />
      <div class="pd-flex">
        <BorderInputPrefix label="+598" />
        <BorderInputPhone
          v-model="building.data.phone"
          label="Teléfono"
          @keyup.enter="onSave"
        />
      </div>
      <FormError
        :show="building.rules.phone"
        message="Ingrese un teléfono válido"
      />
      <BasicLabel label="Tipo de Unidad *" />
      <BorderSelect
        v-model="building.data.unitTypeId"
        label="Tipo de Unidad *"
        :options="store.state.general.unitType"
      />
      <FormError
        :show="building.rules.unitTypeId"
        message="Seleccione el tipo de unidad"
      />
      <BasicLabel label="Teléfono del Responsable" />
      <div class="pd-flex">
        <BorderInputPrefix label="+598" />
        <BorderInputPhone
          v-model="building.data.responsablePhone"
          label="Teléfono del Responsable"
          @keyup.enter="onSave"
        />
      </div>
      <FormError
        :show="building.rules.responsablePhone"
        message="Ingrese un teléfono válido"
      />
      <BasicLabel label="Teléfono de Emergencia" />
      <div class="pd-flex">
        <BorderInputPrefix label="+598" />
        <BorderInputPhone
          v-model="building.data.emergencyPhone"
          label="Teléfono de Emergencia"
          @keyup.enter="onSave"
        />
      </div>
      <FormError
        :show="building.rules.emergencyPhone"
        message="Ingrese un teléfono válido"
      />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode, validation, actions } from "../../constants";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const building = reactive({
      data: {
        id: "",
        companyId:
          store.state.general.companies.length === 1
            ? store.state.general.companies[0].id
            : "",
        name: "",
        identityTypeId: 2,
        identity: "11111111",
        address: "",
        countryId:
          store.state.general.countries.length === 1
            ? store.state.general.countries[0].id
            : 1,
        stateId: "",
        cityId: "",
        email: "complejos@tuappto.com",
        phone: "",
        unitTypeId: "",
        responsablePhone: "",
        emergencyPhone: "",
        towers: "",
      },
      rules: {
        companyId: false,
        name: false,
        identityTypeId: false,
        identity: false,
        address: false,
        countryId: false,
        stateId: false,
        cityId: false,
        email: false,
        phone: false,
        unitTypeId: false,
      },
    });

    const validate = () => {
      let valid = true;

      if (!building.data.companyId) {
        building.rules.companyId = true;
        valid = false;
      }
      if (
        !building.data.name ||
        !validation.characterRegex.test(building.data.name)
      ) {
        building.rules.name = true;
        valid = false;
      }
      if (!building.data.identityTypeId) {
        building.rules.identityTypeId = true;
        valid = false;
      }
      if (
        !building.data.identity ||
        !building.data.identityTypeId ||
        building.data.identityTypeId === 1
          ? !validation.validateRUT(building.data.identity)
          : !validation.validateCI(building.data.identity)
      ) {
        building.rules.identity = true;
        valid = false;
      }
      if (!building.data.address) {
        building.rules.address = true;
        valid = false;
      }
      if (!building.data.countryId) {
        building.rules.countryId = true;
        valid = false;
      }
      if (!building.data.stateId) {
        building.rules.stateId = true;
        valid = false;
      }
      if (!building.data.cityId) {
        building.rules.cityId = true;
        valid = false;
      }
      if (
        !building.data.email ||
        !validation.emailRegex.test(building.data.email)
      ) {
        building.rules.email = true;
        valid = false;
      }
      if (
        building.data.phone &&
        !validation.phoneRegex.test(building.data.phone)
      ) {
        building.rules.phone = true;
        valid = false;
      }
      if (!building.data.unitTypeId) {
        building.rules.unitTypeId = true;
        valid = false;
      }
      if (
        building.data.responsablePhone &&
        !validation.phoneRegex.test(building.data.phone)
      ) {
        building.rules.responsablePhone = true;
        valid = false;
      }
      if (
        building.data.emergencyPhone &&
        !validation.phoneRegex.test(building.data.phone)
      ) {
        building.rules.emergencyPhone = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: building.data.id,
        companyId: building.data.companyId,
        name: building.data.name,
        identityTypeId: building.data.identityTypeId,
        identity: building.data.identity,
        address: building.data.address,
        countryId: building.data.countryId,
        stateId: building.data.stateId,
        cityId: building.data.cityId,
        email: building.data.email,
        phone: `+598 ${building.data.phone}`,
        unitTypeId: building.data.unitTypeId,
        responsablePhone:
          building.data.responsablePhone &&
          `+598 ${building.data.responsablePhone}`,
        emergencyPhone:
          building.data.emergencyPhone &&
          `+598 ${building.data.emergencyPhone}`,
      };

      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(
          actions.buildingActions.update,
          request
        );
      } else {
        response = await store.dispatch(
          actions.buildingActions.create,
          request
        );
      }
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedBuildings", []);
        router.push("/complejos/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      await store.dispatch(
        actions.generalActions.companies,
        store.state.auth.user.company
      );
      store.commit("setLoading", false);
      if (!store.state.openMode) router.push("/complejos/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      if (store.state.openMode === openMode.MODIFY) {
        const selected = store.state.building.selectedBuildings[0];
        building.data.id = selected.id;
        building.data.companyId = selected.companyId;
        building.data.name = selected.name;
        building.data.identityTypeId = selected.identityTypeId;
        building.data.identity = selected.identity;
        building.data.address = selected.address;
        building.data.countryId = selected.countryId;
        building.data.stateId = selected.stateId;
        building.data.cityId = selected.cityId;
        building.data.email = selected.email;
        building.data.phone = selected.phone
          ? selected.phone.split(" ")[1]
          : "";
        building.data.unitTypeId = selected.unitTypeId;
        building.data.responsablePhone = selected.responsablePhone
          ? selected.responsablePhone.split(" ")[1]
          : "";
        building.data.emergencyPhone = selected.emergencyPhone
          ? selected.emergencyPhone.split(" ")[1]
          : "";
      }
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (building.data.companyId) building.rules.companyId = false;
      if (
        building.data.name &&
        validation.characterRegex.test(building.data.name)
      )
        building.rules.name = false;
      if (building.data.identityTypeId) building.rules.identityTypeId = false;
      if (
        building.data.identity &&
        building.identityTypeId &&
        building.identityTypeId === 1
          ? validation.validateRUT(building.data.identity)
          : validation.validateCI(building.data.identity)
      )
        building.rules.identity = false;
      if (building.data.address) building.rules.address = false;
      if (building.data.countryId) building.rules.countryId = false;
      if (building.data.stateId) building.rules.stateId = false;
      if (building.data.cityId) building.rules.cityId = false;
      if (
        building.data.email &&
        validation.emailRegex.test(building.data.email)
      )
        building.rules.email = false;
      if (
        !building.data.phone ||
        validation.phoneRegex.test(building.data.phone)
      )
        building.rules.phone = false;
      if (building.data.unitTypeId) building.rules.unitTypeId = false;
      if (
        !building.data.responsablePhone ||
        validation.phoneRegex.test(building.data.phone)
      )
        building.rules.responsablePhone = false;
      if (
        !building.data.emergencyPhone ||
        validation.phoneRegex.test(building.data.phone)
      )
        building.rules.emergencyPhone = false;
    });

    return { store, building, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
